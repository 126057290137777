<template>
    <CHeader fixed with-subheader light>
        <CSubheader class="px-3" style="padding-left: 0px!important;">
            <b-col cols="6" class="p-0 mt-1">
                <ul class="list-group list-group-horizontal">
                    <li class="list-group-item border-0 d-lg-none">
                        <CToggler
                            in-header
                            class="d-lg-none mt-2"
                            @click="$store.commit('toggleSidebarMobile')"
                        />
                        <CToggler
                            in-header
                            class="d-md-down-none mt-2"
                            @click="$store.commit('toggleSidebarDesktop')"
                        />
                    </li>
                    <li class="list-group-item border-0 d-md-down-none"
                        style="line-height: 28px;width:350px"
                        v-if="$root.hasAccess('header','view_all')"
                    >
                        <div>
                            <b-form-group class="mb-0">
                                <b-input-group>
                                    <b-input v-model="search"
                                             size="sm"
                                             id="common_search"
                                             :placeholder="$t('common.label.common_search')"
                                             @keydown="handleKeyDown"
                                    />
                                    <b-input-group-append>
                                        <b-button v-if="showSearchResult" size="sm"
                                                  @click="()=>{search=null;showSearchResult=false}">
                                            <font-awesome-icon size="sm" icon="times"/>
                                        </b-button>
                                        <span v-else class="hot_key_placeholder">Ctrl+K</span>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>

                            <div v-if="showSearchResult"
                                 id="notclickdiv"
                                 class="bg-white p-2"
                                 @click="showSearchResult=true"
                                 style="position: absolute;color:#4f5d73;width:600px;border: solid 1px #4f5d73;"
                            >
                                <b-row>
                                    <b-col cols="6" v-for="(data,key) in searchData"
                                           :key="key"
                                           v-if="data.length && !key.includes('_found')"
                                    >
                                        <font-awesome-icon class="mr-1" :icon="getIconByKey(key)"/>
                                        <strong>{{ $t('common.search_category.' + key) }}
                                            <span v-if="searchData[key + '_found']>0">({{
                                                    searchData[key + '_found']
                                                }})</span></strong>
                                        <nav class="search-nav">
                                            <b-nav vertical>
                                                <b-nav-item v-for="(item,index) in data"
                                                            :key="'item_'+index"
                                                            @click="openSearchItem(key, item)"
                                                >
                                                    <div v-if="key=='offerings'">
                                                        {{ '[' + item.id + '] ' }}{{ item.number }}
                                                    </div>
                                                    <div v-if="key=='customers'">
                                                        {{ '[' + item.id + '] ' }}{{ item.agenturname }}
                                                    </div>
                                                    <div v-if="key=='persons'">
                                                        {{ '[' + item.id + '] ' }}{{ item.name + ' ' + item.vorname }}
                                                    </div>
                                                    <div v-if="key=='articles'">{{ '[' + item.id + '] ' }}{{
                                                            item.sku
                                                        }}
                                                    </div>
                                                    <div v-if="key=='invoices'">{{ '[' + item.id + '] ' }}{{
                                                            item.number
                                                        }}
                                                    </div>
                                                    <div v-if="key=='contracts'">{{ '[' + item.id + '] ' }}{{
                                                            item.file
                                                        }}
                                                    </div>
                                                    <div v-if="key=='incoming_invoices'">{{
                                                            '[' + item.id + ']' + (item.rechnungsnummer ? ' ' + item.rechnungsnummer : '')
                                                        }}
                                                    </div>
                                                </b-nav-item>
                                            </b-nav>
                                        </nav>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                    </li>
                    <li class="list-group-item border-0 text-right d-md-down-none"
                        style="line-height: 28px;"
                        v-if="$root.hasAccess('header','view_all')">
                        {{ $t('common.label.disk_space') }}
                        <strong>{{ formatMb(getSpace().filled ? getSpace().filled : 0) }} / <span
                            v-html="formatMb(getSpace().available)"></span></strong>
                    </li>
                </ul>

            </b-col>

            <b-col cols="6" class="mt-1 p-0">
                <ul class="list-group list-group-horizontal" style="justify-content: end">
                    <li class="list-group-item  border-0 text-right"
                        v-if="$root.hasAccess('header','view_all')">
                        <b-button @click="helping"
                                  size="sm"
                                  variant="light"
                        >
                            <font-awesome-icon class="show_help" icon="question"/>
                        </b-button>
                    </li>
                    <li class="list-group-item border-0 text-right"
                        v-if="supportAllowed">
                        <b-button @click="$router.push({path: '/support'}).catch(()=>{})"
                                  size="sm"
                                  variant="light"
                        >
                            <font-awesome-icon icon="headset"/>
                        </b-button>

                    </li>
                    <li class="list-group-item border-0 text-right" v-if="$root.hasAccess('header','view_all')">
                        <!--                        <CBreadcrumbRouter class="border-0 mb-0"/>-->
                        <b-dropdown class="mt-0"
                                    left
                                    variant="light"
                                    size="sm"
                        >
                            <template #button-content>
                                <font-awesome-icon class="mr-2" icon="folder-plus"/>
                            </template>

                            <b-dropdown-item
                                @click="$root.$children[0].openModal('customer-modal',{})"
                            >
                                <font-awesome-icon icon="user"
                                                   class="mr-2"
                                />
                                {{ $t('common.button.create_customer') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('project-form', {}, null, {width: '800px'})"
                            >
                                <font-awesome-icon icon="file"
                                                   class="mr-2"
                                />
                                {{ $t('common.button.create_offering') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('project-form', {}, null, {width: '800px'})"
                            >
                                <font-awesome-icon icon="file-invoice"
                                                   class="mr-2"
                                />
                                {{ $t('common.button.create_invoice') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('article-modal', {})"
                            >
                                <font-awesome-icon icon="barcode"
                                                   class="mr-2"
                                />
                                {{ $t('common.button.create_article') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('pre-todo-modal', {}, null, {width: '800px'})"
                            >
                                <font-awesome-icon icon="list-ol"
                                                   class="mr-2"
                                />
                                {{ $t('common.button.create_todo') }}
                            </b-dropdown-item>

                            <b-dropdown-item v-for="(pluginAction,index) in pluginActions"
                                             :key="index"
                                             @click="$root.$children[0].openModal(pluginAction.action, {},null, {width: pluginAction.width ? pluginAction.width: '800px'})"
                            >
                                <font-awesome-icon icon="plus"
                                                   class="mr-2"
                                />
                                {{ $t(pluginAction.title) }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </li>
                    <li class="list-group-item border-0 text-right"
                        v-if="$root.hasAccess('header','view_all') && $root.hasAccess('settings','view')"
                    >
                        <b-dropdown class="mt-0"
                                    left
                                    variant="light"
                                    size="sm"
                                    right
                        >
                            <template #button-content>
                                <font-awesome-icon icon="cogs"
                                                   role="button"
                                                   class="mr-2"
                                />
                            </template>

                            <b-dropdown-item @click.prevent="follow('/settings/global')">
                                {{ $t('common.menu.settings.global') }}
                            </b-dropdown-item>
                            <b-dropdown-item v-for="(item,index) in this.getIitems()"
                                             @click.prevent="follow(item.to)"
                                             :key="index"
                            >
                                <font-awesome-icon class="mr-2" v-if="item.icon" :icon="['fa', item.icon]"/>
                                {{ item.label ? item.label : $t('common.menu.settings.' + item.name) }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </li>
                    <li class="list-group-item  border-0 text-right">
                        <b-dropdown class="mt-0"
                                    left
                                    variant="light"
                                    size="sm"
                                    right
                        >
                            <template #button-content>
                                <font-awesome-icon class="mr-2" icon="globe" role="button"/>
                                <span class="d-md-down-none">
                                <strong class="text-uppercase">{{ getLocale() }}</strong>
                                </span>
                            </template>

                            <b-dropdown-item v-for="language in languages()"
                                             :key="language.code"
                                             @click.prevent="$router.push({path: $router.currentRoute.path + '?locale=' + language.code}).catch(() => {})"
                            >
                                {{ language.name }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </li>
                    <li class="list-group-item border-0 text-right" v-if="$root.hasAccess('header','view_all')">
                        <b-dropdown class="mt-0"
                                    right
                                    variant="light"
                                    size="sm"
                        >
                            <template #button-content>
                                <font-awesome-icon class="mr-2" icon="bell"/>
                                <CBadge v-if="new_notifications_count>0" color="danger">
                                    <template v-if="new_notifications_count<100">
                                        {{ new_notifications_count }}
                                    </template>
                                    <template v-else>99+</template>
                                </CBadge>
                            </template>

                            <b-dropdown-item
                                v-if="new_notifications_count==0"
                            >
                                <font-awesome-icon icon="info"
                                                   class="mr-2"
                                />
                                {{ $t('common.user_notifications.no_new_notifications') }}
                            </b-dropdown-item>

                            <template v-for="(notificationGroup,notificationKey) in getNotifications()">
                                <template v-if="notificationKey=='news'">
                                    <b-dropdown-item v-for="notification in notificationGroup"
                                                     @click="()=>{
                                                         $root.$children[0].openModal('show-news-modal', {news: notification.data}, null, {width: '800px'});
                                                         closeNotification(notificationKey, notification.id)
                                                     }"
                                    >
                                        <CBadge class="mr-2" color="danger">1</CBadge>
                                        {{ $t('common.user_notifications.news_message') }}

                                        <div style="position: absolute;right:0px;">
                                            <font-awesome-icon
                                                @click.stop="closeNotification(notificationKey, notification.id)"
                                                class="ml-2 mr-2"
                                                icon="times"
                                            />
                                        </div>
                                    </b-dropdown-item>
                                </template>
                                <template v-if="notificationKey=='incoming_mail'">
                                    <b-dropdown-item v-for="notification in notificationGroup"
                                                     @click="()=>{
                                                         $root.$children[0].openModal('customer-modal', {customerId:notification.data.customer_id}, null);
                                                         closeNotification(notificationKey, notification.id)
                                                     }"
                                    >
                                        <CBadge class="mr-2" color="danger">1</CBadge>
                                        {{ $t('common.user_notifications.incoming_mail') }}
                                        <template v-if="notification.data.sender_name">
                                            {{ notification.data.sender_name }}
                                        </template>
                                        <template v-else>{{ notification.data.sender_email }}</template>

                                        <div style="position: absolute;right:0px;">
                                            <font-awesome-icon
                                                @click.stop="closeNotification(notificationKey, notification.id)"
                                                class="ml-2 mr-2"
                                                icon="times"
                                            />
                                        </div>
                                    </b-dropdown-item>
                                </template>
                                <template v-if="notificationKey=='export'">
                                    <b-dropdown-item v-for="notification in notificationGroup"
                                                     @click="()=>{
                                                         downloadExportFile(notification.data.filename);
                                                         closeNotification(notificationKey, notification.id)
                                                     }"
                                    >
                                        <CBadge class="mr-2" color="danger">1</CBadge>
                                        {{ $t('common.user_notifications.export') }} {{ notification.data.filename }}

                                        <div style="position: absolute;right:0px;">
                                            <font-awesome-icon
                                                @click.stop="closeNotification(notificationKey, notification.id)"
                                                class="ml-2 mr-2"
                                                icon="times"
                                            />
                                        </div>
                                    </b-dropdown-item>
                                </template>
                                <template v-if="notificationKey=='todo'">
                                    <b-dropdown-item v-for="notification in notificationGroup"
                                                     @click="()=>{
                                                         if(notification.data.customer_id) {
                                                             $root.$children[0].openModal('customer-modal',{customerId:notification.data.customer_id})
                                                         } else {
                                                            $root.$children[0].openModal('todo-modal',{id:notification.data.id})
                                                         }
                                                         closeNotification(notificationKey, notification.id)
                                                     }"
                                    >
                                        <CBadge class="mr-2" color="danger">1</CBadge>
                                        {{ $t('common.user_notifications.todo') }} {{ notification.data.title }}

                                        <div style="position: absolute;right:0px;">
                                            <font-awesome-icon
                                                @click.stop="closeNotification(notificationKey, notification.id)"
                                                class="ml-2 mr-2"
                                                icon="times"
                                            />
                                        </div>
                                    </b-dropdown-item>
                                </template>
                                <template v-if="notificationKey=='job_error'">
                                    <b-dropdown-item @click="()=>{
                                                            $router.push({path: '/failed-jobs'}).catch(() => {});
                                                         closeNotification(notificationKey)
                                                     }">
                                        <CBadge class="mr-2" color="danger">
                                            <template v-if="notificationGroup.length<100">
                                                {{ notificationGroup.length }}
                                            </template>
                                            <template v-else>99+</template>
                                        </CBadge>
                                        {{ $t('common.user_notifications.jobs_errors') }}
                                        <div style="position: absolute;right:0px;">
                                            <font-awesome-icon
                                                @click.stop="closeNotification(notificationKey)"
                                                class="ml-2 mr-2"
                                                icon="times"
                                            />
                                        </div>
                                    </b-dropdown-item>
                                </template>

                            </template>
                        </b-dropdown>
                    </li>
                    <li class="list-group-item  border-0 text-right">
                        <b-dropdown class="mt-0"
                                    left
                                    variant="warning"
                                    size="sm"
                                    right
                        >
                            <template #button-content>
                                <font-awesome-icon class="mr-2" icon="user" role="button"/>
                                <span class="d-md-down-none">
                                <strong v-if="getMe().vorname.length+getMe().name.length>0">{{
                                        getMe().vorname
                                    }} {{ getMe().name }}</strong>
                                <strong v-else>{{ getMe().email }}</strong>
                                </span>
                            </template>

                            <b-dropdown-item
                                @click.prevent="$router.push({path: '/settings/users', query: {me: '1'}}).catch(() => {})">
                                <font-awesome-icon class="mr-2" icon="user"/>
                                {{ $t('common.menu.user') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click.prevent="$router.push({path: '/logout'}).catch(() => {})">
                                <font-awesome-icon class="mr-2" icon="power-off"/>
                                {{ $t('common.menu.logout') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </li>
                </ul>
            </b-col>
        </CSubheader>
    </CHeader>
</template>

<script>
import {mapGetters} from 'vuex'
import _ from 'underscore'

export default {
    name: 'TheHeader',
    data() {
        return {
            news: {},
            supportAllowed: false,
            doneCount: null,
            failedCount: null,
            interval: null,
            showHelp: false,
            showSettings: false,
            pluginActions: [],
            search: null,
            searchData: null,
            showSearchResult: false,
            new_notifications_count: 0,
            items: [
                {
                    name: 'general',
                    module: 'settings',
                    to: '/settings/general',
                    icon: 'cogs',
                },
                {
                    name: 'customer',
                    module: 'customers',
                    to: '/settings/customer',
                    icon: 'briefcase',
                },
                {
                    name: 'documents',
                    module: 'settings',
                    to: '/settings/documents',
                    icon: 'file-invoice',
                },
                {
                    name: 'export',
                    module: 'export',
                    to: '/settings/export',
                    icon: 'file-export',
                },
                {
                    name: 'attributes',
                    module: 'settings',
                    to: '/settings/attributes',
                    icon: 'braille',
                },
                {
                    name: 'shipping',
                    module: 'settings',
                    to: '/settings/shipping',
                    icon: 'dolly-flatbed',
                },
                {
                    name: 'stocks',
                    module: 'settings',
                    to: '/settings/stocks',
                    icon: 'dolly',
                },
                {
                    name: 'users',
                    module: 'settings',
                    to: '/settings/users',
                    icon: 'user',
                },
                {
                    name: 'emails',
                    module: 'settings',
                    to: '/settings/emails',
                    icon: 'envelope',
                },
                {
                    name: 'statuses',
                    module: 'settings',
                    to: '/settings/statuses',
                    icon: 'history',
                },
                {
                    name: 'paymentmethods',
                    module: 'settings',
                    to: '/settings/paymentmethods',
                    icon: 'euro-sign',
                },
                {
                    name: 'crm',
                    module: 'crm',
                    to: '/settings/crm',
                    icon: 'heart',
                },
                {
                    name: 'workflows',
                    module: 'settings',
                    to: '/settings/workflows',
                    icon: 'map-signs',
                },
                {
                    name: 'kb',
                    module: 'settings',
                    to: '/settings/kb',
                    icon: 'certificate',
                },
                {
                    name: 'categories',
                    module: 'settings',
                    to: '/settings/categories',
                    icon: 'stream',
                },
                {
                    name: 'languages',
                    module: 'settings',
                    to: '/settings/languages',
                    icon: 'language',
                },
                {
                    name: 'modules',
                    module: 'settings',
                    to: '/settings/modules',
                    icon: 'puzzle-piece',
                },
                {
                    name: 'articles',
                    module: 'settings',
                    to: '/settings/articles',
                    icon: 'barcode',
                },
                {
                    name: 'channels',
                    module: 'settings',
                    to: '/settings/channels',
                    icon: 'boxes',
                },
                {
                    name: 'custom_fields',
                    module: 'custom-fields',
                    to: '/custom-fields',
                    icon: 'server',
                },
                {
                    name: 'integrations',
                    module: 'integrations',
                    to: '/settings/integrations',
                    icon: 'puzzle-piece',
                },
            ],
        }
    },
    mounted: function () {
        document.addEventListener('click', this.handleOutsideClick);
        this.checkJobsCount()
        this.setUpdateInterval()

        this.getPlugins().forEach(pluginName => {
            if (window[pluginName] && window[pluginName].methods.getHeaderActions) {
                window[pluginName].methods.getHeaderActions().forEach((element) => {
                    this.pluginActions.push(element);
                });
            }
            if (pluginName == 'support') {
                this.supportAllowed = true;
            }
        })

        this.showNewsModal()
        this.showSupportModal()
    },
    beforeDestroy() {
        clearInterval(this.interval)
        document.removeEventListener('click', this.handleOutsideClick);
        this.interval = null
    },
    watch: {
        $route(to, from) {
            this.showHelp = false;
        },
        search: _.debounce(function (query) {
            this.getData(query)
        }, 250),
    },
    methods: {
        ...mapGetters('CommonData', ['getSpace', 'getMe', 'getPlugins', 'getCommonData']),
        ...mapGetters('Jobs', ['getJobsCount', 'getFailedJobs', 'getDoneJobs', 'getFailedGroup', 'getNotifications']),
        ...mapGetters('News', ['getThenew']),
        ...mapGetters('I18NStore', ['languages']),
        closeNotification(notificationKey, id = null) {
            this.axios.post(window.apiUrl + '/common-data/close-notification', {
                notificationKey,
                id,
            }, {'skip_loading': true})
                .then(() => {
                    this.checkJobsCount()
                })

        },
        getData(query) {
            if (!query || !query.length) {
                return
            }
            let params = {search: query}
            this.axios.post(window.apiUrl + '/common-data/search', params, {'skip_loading': true})
                .then(response => {
                    this.searchData = response.data
                    this.showSearchResult = true
                })
        },
        handleOutsideClick(event) {
            if (this.showSearchResult) {
                const searchInput = document.getElementById('common_search');
                const notClickDiv = document.getElementById('notclickdiv');
                if (
                    searchInput &&
                    notClickDiv &&
                    !searchInput.contains(event.target) &&
                    !notClickDiv.contains(event.target)
                ) {
                    this.search = null
                    this.showSearchResult = false
                }
            }
        },
        downloadExportFile(file) {
            this.$root.downloadDocument(file, 'export')
        },
        handleKeyDown(event) {
            if (event.key === 'Enter') {
                this.$router.push({path: '/search/' + this.search})
                this.showSearchResult = false
            }
            if (event.key === 'Escape') {
                this.search = null
                this.showSearchResult = false
            }
        },
        openSearchItem(key, item) {
            if (key == 'offerings') {
                this.$root.$children[0].openModal('offering-modal', {id: item.id})
            } else if (key == 'customers') {
                this.$root.$children[0].openModal('customer-modal', {customerId: item.id})
            } else if (key == 'persons') {
                this.$root.$children[0].openModal('customer-modal', {customerId: item.kunden_Id})
            } else if (key == 'articles') {
                this.$root.$children[0].openModal('article-modal', {id: item.id})
            } else if (key == 'invoices') {
                this.$root.openDocument(item.number)
            } else if (key == 'contracts') {
                this.$root.openDocument(item.file, 'customer_contracts')
            } else if (key == 'incoming_invoices') {
                this.$root.$children[0].openModal('incoming-invoices-modal', {
                        id: item.id,
                    }
                )
            }
        },
        hideSearchResult() {
            this.$nextTick(() => {
                this.showSearchResult = false
            })
        },
        getIconByKey(key) {
            const map = {
                'offerings': 'chart-bar',
                'customers': 'briefcase',
                'persons': 'users',
                'articles': 'barcode',
                'invoices': 'file-invoice',
                'incoming_invoices': 'file',
            }

            return map[key]
        },
        getLocale() {
            return localStorage.locale
        },
        showNewsModal() {
            setTimeout(() => {
                if (window.localStorage) {
                    let commonData = this.getCommonData();
                    let storageName = 'lastShowNews_' + commonData.domain + '_' + commonData.user.id;
                    var showPopup = false;
                    var lastShowDate = localStorage.getItem(storageName);
                    var today = new Date().setHours(0, 0, 0, 0)

                    if (!lastShowDate) {
                        showPopup = true;
                    } else {
                        if (today > parseInt(lastShowDate)) {
                            showPopup = true;
                        }
                    }
                    if (showPopup) {
                        this.$store.dispatch('News/fetchShowThenew', {
                            domain: commonData.domain,
                            userId: commonData.user.id,
                            id: 0
                        })
                            .then(() => {
                                this.news = this.getThenew()
                                if (this.news.id) {
                                    this.$root.$children[0].openModal('show-news-modal', {news: this.news}, this.refreshTable, {width: '800px'})
                                }
                            })
                            .finally(() => {
                                this.loading = false;
                            })
                    }
                }
            }, 3000)
        },
        showSupportModal() {
            setTimeout(() => {
                if (window.localStorage && this.supportAllowed) {
                    let commonData = this.getCommonData();
                    let storageName = 'lastShowSupport_' + commonData.domain + '_' + commonData.user.id;
                    var showPopup = false;
                    var lastShowDate = localStorage.getItem(storageName);
                    var today = new Date().setHours(0, 0, 0, 0)

                    if (!lastShowDate) {
                        showPopup = true;
                    } else {
                        if (today > parseInt(lastShowDate)) {
                            showPopup = true;
                        }
                    }
                    if (showPopup) {
                        window.axios.get(window.apiUrl + '/support/show-supports/' + commonData.domain, {'skip_loading': true})
                            .then(response => {
                                if (typeof response.data != "undefined" && response.data.length > 0) {
                                    this.$root.$children[0].openModal('show-supports-modal', {support: response.data}, this.refreshTable, {width: '800px'})
                                }
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    }
                }
            }, 3500)
        },
        setUpdateInterval() {
            this.interval = window.setInterval(() => {
                this.checkJobsCount()
            }, 20000)
        },
        checkJobsCount() {
            this.$store.dispatch('Jobs/fetchJobsStatus')
                .then(() => {
                    let failedCount = this.getFailedJobs().length
                    let doneCount = this.getDoneJobs()

                    if (this.doneCount != null && this.failedCount != null) {
                        if (doneCount > this.doneCount) {
                            this.$root.showMessage(this.$t('common.form.title.success'), this.$t('common.toasts.jobs_success', {count: doneCount - this.doneCount}), 'success')
                        }
                        if (failedCount > this.failedCount) {
                            if (this.getFailedGroup()) {
                                const root = this.$root
                                const failedGroup = this.getFailedGroup()
                                const h = this.$createElement
                                const vNodesMsg = h(
                                    'div',
                                    [
                                        h('div',
                                            [
                                                h('b-button', {
                                                    props: {variant: 'warning'}, on: {
                                                        click(event) {
                                                            root.$children[0].openModal('job-info-modal', {job_group: failedGroup})
                                                        }
                                                    }
                                                }, [

                                                    h('font-awesome-icon', {props: {icon: 'times'}, class: ['mr-2']}),
                                                    this.$t('common.toasts.jobs_error', {count: this.failedCount - this.failedCount}),
                                                    this.$t('common.button.open')
                                                ]),
                                            ]
                                        )
                                    ]
                                )

                                this.$root.showMessage(
                                    this.$t('common.form.title.error'),
                                    [vNodesMsg],
                                    'danger',
                                )
                            } else {
                                this.$root.showMessage(this.$t('common.form.title.error'), this.$t('common.toasts.jobs_error', {count: failedCount - this.failedCount}), 'danger')
                            }
                        }
                    }

                    this.doneCount = doneCount
                    this.failedCount = failedCount

                    let notificationsCount = 0
                    Object.keys(this.getNotifications()).forEach(key => {
                        notificationsCount += this.getNotifications()[key].length
                    });
                    this.new_notifications_count = notificationsCount
                })
        },
        formatMb(mb) {
            if (mb == null) {
                return '&infin;'
            }
            if (mb > 1024) {
                return (mb / 1024).toFixed(2) + 'GB'
            }

            return mb + 'MB'
        },
        markAsShown(type) {
            let ids = []
            if (type == 'failed') {
                ids = this.getFailedJobs().map(job => job.id)
            }
            this.$store.dispatch('Jobs/markAsShown', ids).then(() => {
                this.checkJobsCount()
            })
        },
        markAsShownId(id) {
            let ids = [id]
            this.$store.dispatch('Jobs/markAsShown', ids).then(() => {
                this.checkJobsCount()
            })
        },
        helping() {
            this.showHelp = !this.showHelp
            this.$emit('help-change', this.showHelp);

            if (this.showHelp) {
                setTimeout(() => {
                    const el = document.getElementsByClassName('right_sidebar')[0];
                    if (el) {
                        el.scrollIntoView();
                    }
                }, 1000);
            }
        },
        getIitems() {
            let menu = this.items;

            let newItems = [];
            this.getPlugins().forEach(pluginName => {
                const plugin = window[pluginName] || null;
                if (plugin && plugin.methods.getMenuStructure) {
                    plugin.methods.getMenuStructure(this.getCommonData())
                        .forEach((componentStructure, index) => {
                            if (componentStructure.name == 'settings' || componentStructure.name == 'settings-global') {
                                if (componentStructure.sub_menu) {
                                    componentStructure.sub_menu.forEach(item => {
                                        newItems.push(item)
                                    })
                                }
                            }
                        })
                }
            })
            menu = menu.concat(newItems);

            let subMenuSorted = Object.keys(menu).sort(function (a, b) {
                return menu[a].name.localeCompare(menu[b].name)
            })
            let subMenuItems = []
            subMenuSorted.forEach(key => {
                subMenuItems.push(menu[key])
            })

            return subMenuItems;
        },
        follow(to) {
            this.$router.push({path: to}).catch(() => {
            })
        },
    }
}
</script>