export default {
    'customers': {
        icon: 'briefcase',
        ordering: 205,
        to: '/customers',
        items: [
            {
                name: 'all-customers',
                href: '/kunden',
                to: '/customers',
                module: 'customers',
            },
            {
                name: 'cotravelers',
                to: '/cotravelers',
                module: 'cotravelers',
            },
        ]
    },
    'contracts': {
        icon: 'file-invoice',
        to: '/customer-contract',
        ordering: 210,
        items: [
            {
                name: 'customer-contract',
                href: '/customer-contract',
                to: '/customer-contract',
                module: 'contract',
            },
            {
                name: 'contract-settings',
                to: '/contract-settings',
                module: 'contract-settings',
            },
        ]
    },
    'products': {
        icon: 'barcode',
        ordering: 215,
        to: '/articles',
        items: [
            {
                name: 'hotels',
                to: '/hotels',
                module: 'hotels',
            },
            {
                name: 'all-products',
                href: '/products',
                to: '/articles',
                module: 'articles',
            },
            {
                name: 'inventory',
                to: '/articles/inventory',
                module: 'articles-inventory',
            },
            {
                name: 'inventory-list',
                to: '/articles/inventory-list',
                module: 'articles-inventory',
            },
        ]
    },
    'sales': {
        icon: 'chart-bar',
        ordering: 220,
        to: '/sales',
        items: [
            {
                to: '/sales',
                name: 'sales',
                module: 'sales',
            },
            {
                to: '/combine-files',
                name: 'combine-files',
                module: 'combine-files',
            },
        ]
    },
    'crm': {
        icon: 'heart',
        ordering: 225,
        to: '/crm',
        items: [
            {
                name: 'crm-todos',
                module: 'crm-todos',
                to: '/crm/todos',
            },
            {
                name: 'crm-customers',
                module: 'crm-customers',
                to: '/crm/customers',
                href: '/crm?status=contact'
            },
            {
                name: 'crm',
                to: '/crm',
                href: '/crm',
                module: 'crm',
            },
            {
                name: 'aftersale',
                module: 'crm-aftersale',
                to: '/crm/done',
                href: '/crm?status=done',
            },
            {
                name: 'crm-contracts',
                module: 'crm-contracts',
                to: '/crm-contracts',
                href: '/crm-contracts'
            },
            {
                name: 'crm-notifications',
                module: 'crm-notifications',
                to: '/crm/notifications',
                href: '/crm/wiedervorlagen',
            },
        ]
    },
    'returns': {
        module: 'returns',
        to: '/returns',
        icon: 'hand-point-left',
        ordering: 255,
    },
    'supply': {
        icon: 'dolly',
        ordering: 230,
        to: '/supply',
        items: [
            {
                to: '/supply',
                name: 'supply-module',
                module: 'supply',
            },
            {
                to: '/supply-offers',
                name: 'supply-offers',
                module: 'supply-offers',
            },
        ]
    },
    'shipments': {
        module: 'shipments',
        to: '/shipments',
        icon: 'dolly-flatbed',
        ordering: 240,
    },
    'multichannel': {
        icon: 'boxes',
        module: 'multichannel',
        to: '/multichannel/1',
        items: [],
        ordering: 245,
    },
    'reports': {
        icon: 'chart-line',
        ordering: 250,
        items: [
            {
                name: 'customer_calls',
                module: 'reports',
                to: '/customer-calls',
                permissions: ['view_all', 'customer_calls']
            },
            {
                name: 'customer_calls2',
                module: 'reports',
                to: '/customer-calls2',
                permissions: ['view_all', 'customer_calls2']
            },
            {
                name: 'paid_outgoing',
                module: 'reports',
                to: '/paid-outgoing',
                permissions: ['view_all', 'paid_outgoing']
            },
        ]
    },
    'account-book': {
        icon: 'euro-sign',
        ordering: 250,
        to: '/invoice',
        items: [
            {
                name: 'invoice',
                href: '/rechnungsbuch',
                module: 'invoice',
                to: '/invoice',
            },
            {
                name: 'incoming-invoice',
                module: 'incoming-invoice',
                href: '/rechnungsbuch/eingang',
                to: '/incoming-invoice',
            },
            {
                name: 'bar-kasse',
                href: '/rechnungsbuch/index/kassenbuch',
                to: '/cash-invoice',
                module: 'cash',
            },
            {
                name: 'deposits',
                module: 'deposits',
                to: '/deposits',
            },
            {
                name: 'bank-transfers',
                module: 'bank-transfers',
                to: '/bank-transfers',
            },
            {
                name: 'bank-transfers-debit',
                module: 'bank-transfers-debit',
                to: '/bank-transfers-debit',
            },
            {
                name: 'liabilities',
                module: 'liabilities',
                to: '/liabilities',
            },
            {
                name: 'x_invoices',
                module: 'x-invoices',
                to: '/x-invoices',
            },
            {
                name: 'monthly-invoices',
                module: 'monthly-invoices',
                to: '/monthly-invoices',
            },
            {
                name: 'profit-and-loss',
                module: 'profit-and-loss',
                to: '/profit-and-loss',
            }
        ]
    },
    'system-tools': {
        icon: 'server',
        ordering: 270,
        items: [
            {
                to: '/sales-attachments',
                name: 'sales-attachments',
                module: 'sales-attachments',
            },
            {
                to: '/global-attachments',
                name: 'global-attachments',
                module: 'global-attachments',
            },
            {
                to: '/news',
                name: 'news',
                module: 'news',
            },
            {
                name: 'api-logs',
                module: 'api-logs',
                to: '/api-logs',
            },
            {
                name: 'mails',
                module: 'mails',
                to: '/mails',
            },
            {
                name: 'jobs',
                module: 'jobs',
                to: '/jobs',
            },
            {
                name: 'failed-jobs',
                module: 'jobs',
                to: '/failed-jobs',
            },
            {
                name: 'export-history',
                module: 'export-history',
                to: '/export-history',
            },
            {
                name: 'combine-files',
                module: 'combine-files',
                to: '/combine-files',
            }
        ]
    },
}